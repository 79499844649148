.color1 {	background: rgb(255,255,255) !important;color: rgb(0,0,0) !important; }
.color2 {	background: rgb(65,65,65) !important; 	color: rgb(255,255,255) !important; }
.color3 {	background: rgb(196,0,4) !important; 		color: rgb(255,255,255) !important; }
.color4 {	background: rgb(199,72,1) !important; 	color: rgb(0,0,0) !important; }
.color5 {	background: rgb(199,197,5) !important; 	color: rgb(0,0,0) !important; }
.color6 {	background: rgb(6,170,3) !important; 		color: rgb(0,0,0) !important; }
.color7 {	background: rgb(5,68,224) !important; 	color: rgb(255,255,255) !important; }
.color8 {	background: rgb(0,142,143) !important; 	color: rgb(0,0,0) !important; }
.color9 {	background: rgb(141,45,145) !important; color: rgb(255,255,255) !important; }
.color10{	background: rgb(200,0,73) !important; 	color: rgb(255,255,255) !important; }

